import React from 'react';

import Layout from '@layouts/Layout';

import Alert from '@components/_ui/Alert';
import Checkpoints from '@components/shop/Checkpoints';


/** Checkout Page */
const Checkout = () => (
    <Layout mainClassName="flow--xlarge" metaData={{ title: 'Checkout Page' }}>

        <header className="hero hero--has-checkpoints">
            <div className="container flow">
                <Checkpoints />
                <h1 className="text-subtitle text-align--center">Checkout</h1>
            </div>
        </header>

        <section className="wrapper background-color--chalk flow--medium">

            <section className="container island--xlarge text-align--center flow--medium">
                <Alert type="warning">
                    <span className="text-label">Checkout disabled</span>
                    <p className="text-paragraph text-paragraph--small">We apologize for any disruption. We are currently in the process of migrating from one payment provider to another. If you would like to continue with your purchase, please email us at <a className="text-link" href="info@perfectpergolas.co.uk">info@perfectpergolas.co.uk</a> and we will process your purchase manually.</p>
                </Alert>
            </section>
            {/* <BasketProvider>
                <CheckoutForm />
            </BasketProvider> */}
        </section>
    </Layout>
);

export default Checkout;
